
import Vue from 'vue'
import { IHeaderOption, KwikMediumContainer, KwikPageToolbar } from 'kwik-vue'
import { ICustomerTransactionRow } from '../types'
import { dispatchGetCustomerTransactionRows } from '../store/actions'

export default Vue.extend({
  name: 'FFCustomerTransactionRows',
  components: { KwikMediumContainer, KwikPageToolbar },
  data () {
    return {
      options: {} as any,
      loading: true,
      customerTransactionRowItems: [] as ICustomerTransactionRow[],
      breadcrumbs: [
        { text: 'Food Factory', disabled: true },
        { text: 'Dettaglio transazione', disabled: false }
      ]
    }
  },
  computed: {
    headers () : IHeaderOption[] {
      const defaultHeaders = [
        {
          text: 'ID',
          sortable: true,
          value: 'id',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Codice lotto',
          sortable: true,
          value: 'batch.code',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Quantità',
          sortable: true,
          value: 'quantity',
          hidden: false,
          align: 'start'
        }
      ] as IHeaderOption[]
      return defaultHeaders
    }
  },
  methods: {
    async loadPage (customerTransactionId: number) {
      this.loading = true
      const response = await dispatchGetCustomerTransactionRows(this.$store, customerTransactionId)
      this.customerTransactionRowItems = response.data
      this.loading = false
    }
  },
  async mounted () {
    const customerTransactionId = +this.$router.currentRoute.params.customer_transaction_id
    this.loadPage(customerTransactionId)
  }
})
